import React, { Component } from 'react';
import Moment from 'react-moment';
import './LastUpdated.scss';

class LastUpdated extends Component {
  render() {
    if (this.props.time !== null) {
      return (
        <div className="top-toast" onClick={() => { this.props.handleClick() }}>
          <Moment interval={15000} date={this.props.time} fromNow />
          <span>
            last updated
          </span>
        </div>
      );
    }

    return (
      <div className="top-toast">
        <em className="text-muted">Loading...</em>
      </div>
    )
  }
};

export default LastUpdated;
