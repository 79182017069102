import React, { Component } from 'react';
import './App.scss';
import CurrentTemp from './CurrentTemp/CurrentTemp';
import TempTable from './TempTable/TempTable';
import Axios from 'axios';
import DailyChart from './DailyChart/DailyChart';
import LastUpdated from './LastUpdated/LastUpdated';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      refreshInterval: null,
      climateData: [],
      lastUpdated: null,
    };
  }

  async componentDidMount() {
    this.fetchData();
    await this.setState({
      refreshInterval: window.setInterval(() => { this.fetchData(); }, 5 * 60 * 1000),
    });
  }

  componentWillUnmount() {
    if (this.state.refreshInterval !== null) {
      window.clearInterval(this.state.refreshInterval);
    }
  }

  async fetchData() {
    const { data } = await Axios.get('/climate_data.json');
    this.setState({
      climateData: data,
      lastUpdated: new Date(),
    });
  }

  render() {
    return (
      <div className="app container">
        <LastUpdated time={this.state.lastUpdated} handleClick={() => { this.fetchData(); }} />
        <div className="row">
          <CurrentTemp climateData={this.state.climateData} />
        </div>

        <div className="row">
          <div className="col-md-12 col-sm-12 col-xs-12">
            <h3>Last 24 Hours</h3>
          </div>
          <DailyChart climateData={this.state.climateData} />
        </div>

        <div className="row">
          <div className="col-md-12 col-sm-12 col-xs-12">
            <h3>Historical Data</h3>
          </div>
          <TempTable climateData={this.state.climateData} />
        </div>
      </div>
    );
  }
}

export default App;
