import React, { Component } from 'react';
import { AreaChart, XAxis, YAxis, Tooltip, Legend, Area, CartesianGrid, ResponsiveContainer } from 'recharts';
import FormatHelper from '../helpers/FormatHelper';
import momentJS from 'moment';

class DailyChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      chartData: [],
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.climateData !== prevProps.climateData) {
      this.buildChartData();
    }
  }

  buildChartData() {
    const chartData = [];
    const climateData = [...this.props.climateData];

    climateData.reverse().forEach((metric) => {
      chartData.push({
        name: momentJS(metric.timestamp).format("HH:mm"),
        temperature: FormatHelper.formatTemperature(metric.temperature, false),
        humidity: metric.humidity,
        pressure: metric.pressure,
      });
    });

    console.log(chartData);
    this.setState({
      chartData: chartData,
    });
  }

  render() {
    if (!this.state.chartData || !this.state.chartData.length) {
      return (
        <div className="col-md-4">
          <div className="alert alert-warning">
            <p>Daily chart is unavailable.</p>
          </div>
        </div>
      )
    }

    const colors = {
      TEMP: '#8C0410',
      HUMIDITY: '#023859',
      PRESSURE: '#400101',
    };

    return (
      <div className="col-md-12 col-sm-12 col-xs-12" style={{ height: '300px' }}>
        <ResponsiveContainer>
          <AreaChart height={300} data={this.state.chartData} margin={{ top: 10, right: 0, left: 0, bottom: 0}}>
            <defs>
              <linearGradient id="colorTemperature" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor={colors.TEMP} stopOpacity={0.8}/>
                <stop offset="95%" stopColor={colors.TEMP} stopOpacity={0.1}/>
              </linearGradient>
              <linearGradient id="colorHumidity" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor={colors.HUMIDITY} stopOpacity={0.8}/>
                <stop offset="95%" stopColor={colors.HUMIDITY} stopOpacity={0.1}/>
              </linearGradient>
              <linearGradient id="colorPressure" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor={colors.PRESSURE} stopOpacity={0.8}/>
                <stop offset="95%" stopColor={colors.PRESSURE} stopOpacity={0.1}/>
              </linearGradient>
            </defs>

            <XAxis dataKey="name" />
            <YAxis yAxisId="left" domain={[0, 100]} />
            <YAxis yAxisId="right" orientation="right" tickFormatter={(t) => Math.round(t)} domain={[980, 1040]} />

            <Tooltip />
            <CartesianGrid strokeDasharray="3 3"/>
            <Legend />
            <Area yAxisId="left" type="monotone" dataKey="temperature" stroke={colors.TEMP} fillOpacity={0.7} fill="url(#colorTemperature)" dot={false} />
            <Area yAxisId="left" type="monotone" dataKey="humidity" stroke={colors.HUMIDITY} fillOpacity={0.7} fill="url(#colorHumidity)" dot={false} />
            <Area yAxisId="right" type="monotone" dataKey="pressure" stroke={colors.PRESSURE} fillOpacity={0.7} fill="url(#colorPressure)" dot={false} />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    )
  }
};

export default DailyChart;
