import React, { Component } from 'react';
import "./CurrentTemp.scss";
import FormatHelper from '../helpers/FormatHelper';

class CurrentTemp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      latestMetric: {
        temperature: null,
        pressure: null,
        humidity: null,
      },
    };
  }

  async componentDidUpdate(prevProps) {
    if (this.props.climateData !== prevProps.climateData) {
      const climateData = [...this.props.climateData];
      await this.setState({
        latestMetric: climateData.shift(),
      });
    }
  }

  render() {
    const { latestMetric } = this.state;

    const metrics = ['temperature', 'humidity', 'pressure'].map((metricName) => {
      const metric = latestMetric[metricName];
      let value;

      if (!metric) {
        value = <em className="text-muted">unavailable</em>
      } else {
        value = FormatHelper.format(metricName, metric);
      }

      return (
        <div className="col-md-4 col-sm-4 col-xs-4" key={`metric-${metricName}`}>
          <dl className="metric">
            <dt>{ metricName }</dt>
            <dd>{ value }</dd>
          </dl>
        </div>
      );
    });

    return (
      <section>
        { metrics }
      </section>
    );
  }
};

export default CurrentTemp;
