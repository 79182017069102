import React from 'react';
import Moment from 'react-moment';
import momentJS from 'moment';
import 'moment-timezone';

const FormatHelper = {
  format: (metricName, value) => {
    switch(metricName.toLowerCase()) {
      case 'temperature':
        return FormatHelper.formatTemperature(value);

      case 'humidity':
        return FormatHelper.formatHumidity(value);

      case 'pressure':
        return FormatHelper.formatPressure(value);

      case 'time':
        return FormatHelper.formatTime(value);

      case 'timestamp':
        return FormatHelper.formatTimestamp(value);

      default:
        throw new Error(`Unknown metric type ${metricName}`);
    }
  },

  formatTemperature: (temp_c, includeSymbol = true) => {
    const temp_f = (temp_c * 1.8) + 32.0;
    if (includeSymbol) {
      return Math.round(temp_f) + 'ºF';
    }
    return Math.round(temp_f);
  },

  formatHumidity: (h) => {
    return Math.round(h) + '%';
  },

  formatPressure: (p) => {
    return Math.round(p) + 'mb';
  },

  formatTime: (t) => {
    return <Moment local format="MM/DD/YY HH:mm">{t}</Moment>;
  },

  formatTimestamp: (t) => {
    return momentJS(t).format("MM/DD/YY HH:mm");
  },
};

export default FormatHelper;
