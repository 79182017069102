import React, { Component } from 'react';
import FormatHelper from '../helpers/FormatHelper';

class TempTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {}
    };
  }

  async componentDidUpdate(prevProps) {
    if (this.props.climateData !== prevProps.climateData) {
      await this.setState({
        data: this.props.climateData,
      });
    }
  }

  render() {
    let tableRows;
    const { data } = this.state;

    if (data.length) {
      tableRows = data.map((row) => {
        return (
          <tr key={`row-${row.id}`}>
            <td>{ FormatHelper.format('time', row.timestamp) }</td>
            <td>{ FormatHelper.format('temperature', row.temperature) }</td>
            <td>{ FormatHelper.format('humidity', row.humidity) }</td>
            <td>{ FormatHelper.format('pressure', row.pressure) }</td>
          </tr>
        )
      })
    } else {
      tableRows = (
        <tr>
          <td colSpan="4" className="text-center">
            <em className="text-muted">Data unavailable</em>
          </td>
        </tr>
      )
    }

    return (
      <div className="col-md-12">
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Time</th>
              <th>Temperature</th>
              <th>Humidity</th>
              <th>Pressure</th>
            </tr>
          </thead>
          <tbody>
            {tableRows}
          </tbody>
        </table>
      </div>
    );
  }
};

export default TempTable;
